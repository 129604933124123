.form-select {
  display: inline-block;
  font-family: inherit;
  outline: 0;
  text-transform: capitalize;
  border: none;
  -webkit-appearance: none;
  box-shadow: 0 0 0px 1px #ddd;
  -webkit-box-shadow: 0 0 0px 1px #ddd;
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px;
  width: 100%;
  transition: box-shadow 1000ms ease;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  &:hover {
    box-shadow: 0 0 0 1px #218f76;
    -webkit-box-shadow: 0 0 0 1px #218f76;
  }

  &:focus {
    box-shadow: 0 0 0 1px #218f76;
    -webkit-box-shadow: 0 0 0 1px #218f76;
  }

  option {
  }
}
