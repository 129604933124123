.slider {
  position: relative;
  margin: 0 auto;
  width: calc(100vw - (100vw - 100%));
  height: 56.25vw;

  .wrapper {
    overflow: hidden;
    position: relative;
    width: calc(100vw - (100vw - 100%));
    height: 56.25vw;
    z-index: 1;
  }

  .slides {
    display: flex;
    position: relative;
    top: 0;
    height: 56.25vw;
  }

  .slide {
    width: calc(100vw - (100vw - 100%));
    height: 56.25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 1s;
    position: relative;
    border-radius: 2px;
    background-size: cover;
    background-position: center;
  }

  .control {
    position: absolute;
    top: 50%;
    color: #555;
    text-align: center;
    border-radius: 50%;
    margin-top: -20px;
    background-color: rgba(255, 255, 255, 0.3);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  .control:hover {
    background-color: rgba(255, 255, 255, 0.8);
    user-select: none;
  }

  .prev,
  .next {
    background-position: center;
    cursor: pointer;
    transition: background-color 0.5s ease;
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 40px;
  }

  .prev:active,
  .next:active {
    transform: scale(0.8);
  }

  .bottom-dots {
    position: absolute;
    bottom: 0;
    display: flex;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);

    .bottom-dot {
      transition: ease-out 0.5s background-color;
      background-color: #fff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 10px;
      box-shadow: 0px 0px 2px 1px #fff;
    }
  }
}
