.contact-form {
  padding: 20px;
  width: 1000px;
  margin: 20px auto 40px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 20px 0;
  }
  .contact-form-title {
    text-align: center;
    color: #218f76;
  }

  .select {
    display: inline-block;
    font-family: inherit;
    outline: 0;
    border: none;
    -webkit-appearance: none;
    box-shadow: 0 0 0px 1px #ddd;
    -webkit-box-shadow: 0 0 0px 1px #ddd;
    border-radius: 5px;
    font-size: 1rem;
    padding: 10px;
    width: 600px;
    transition: box-shadow 1000ms ease;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
    &:hover {
      box-shadow: 0 0 0 3px #218f76;
      -webkit-box-shadow: 0 0 0 3px #218f76;
    }

    &:focus {
      box-shadow: 0 0 0 3px #218f76;
      -webkit-box-shadow: 0 0 0 3px #218f76;
    }

    .default-color {
      color: #fff;
    }
  }
}
