.mail-table-loading-container,
.mail-table-container {
  min-height: 640px;
  @media screen and (max-width: 1000px) {
    width: 100%;
    overflow: hidden;
  }
}
.mail-table {
  width: fit-content;
  height: 524px;
  margin: 20px auto;

  @media screen and (max-width: 1000px) {
    width: 100%;
    overflow: scroll;
  }

  .not-visited {
    background-color: #eee;
  }
  .mail-container {
    display: flex;

    &:first-child {
      -webkit--webkit-box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, 0.122);
      box-shadow: inset 0 1px 0 0 rgba(100, 121, 143, 0.122);
    }
    .mail-details {
      -webkit--webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
      box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
      display: -webkit-box;
      display: -webkit-flex;
      max-height: 52px;
      display: flex;
      align-items: center;
      .name {
        width: 120px;
        padding: 0 10px;
      }

      .reason {
        width: 200px;
        padding: 0 10px;
      }
      .description {
        width: 300px;
        padding: 0 10px;
      }
    }
  }
}
