.image-description-container {
  display: flex;
  height: 400px;
  max-width: 1200px;
  margin: 0 auto 30px;

  @media screen and (max-width: 1200px) {
    height: 25vw;
    max-width: 100vw;
    overflow: hidden;
  }
  @media screen and (max-width: 910px) {
    height: fit-content;
    flex-direction: column !important;
  }

  .image-container {
    width: 700px;
    height: 350px;
    background-size: cover;
    background-position: top;
    background-color: #fff;
    box-shadow: 0 0 1px #555;
    cursor: pointer;
    z-index: 10;

    @media screen and (max-width: 1200px) {
      width: 55vw;
      height: 26vw;
    }

    @media screen and (max-width: 910px) {
      width: 100%;
      height: 50vw;
    }
  }

  .description-container {
    width: 500px;
    height: 400px;
    color: #0a3d62;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 1200px) {
      width: calc(45vw - 40px);
      height: 25vw;
    }

    @media screen and (max-width: 910px) {
      width: 100%;
      height: fit-content;
    }
    .description {
      padding: 20px;

      .title {
        margin-bottom: 20px;
      }
    }
  }

  .project-link {
    display: block;
    margin: 20px auto;
    text-decoration: none;
    border-radius: 10px;
    width: 100px;
    padding: 10px 20px;
    box-shadow: 0 0 1px #2e2e2e;
    background-color: #fff;
    color: #0a3d62;
    transition: all 0.5s ease-in;

    &:hover {
      box-shadow: 0 0 1px #fff;
      background-color: #0a3d62;
      color: #fff;
    }
  }
}
