.page-selection-container {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 20px;
  margin-block-start: 0;

  .page-no {
    list-style: none;
    padding: 5px 10px;
    background-color: #ddd;
    transition: background-color 1s ease;
    cursor: pointer;
    border-right: 1px solid #aaa;
    &:last-child {
      border-right: none;
    }
  }
}
