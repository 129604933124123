.unlocated-address-row {
  transition: background-color 0.5s ease;
  min-width: 100vw;

  &:nth-child(even) {
    background-color: #ccc;
  }
  &:hover {
    background-color: #4d148c;
    color: #fff;
  }

  .unlocated-address-td {
    border: 1px solid #999;
  }

  .route-no-input,
  .search-key-input {
    padding: 4px;
    font-size: 16px;
    margin: 0;
    width: calc(100% - 8px);
    border: none;
    outline: none;
    background-color: #eee;
  }

  .unlocated-row-icon {
    margin: 0 10px;
    cursor: pointer;
    color: #555;
    transition: color 0.5s ease;

    &:hover {
      color: #ff6200;
    }
  }
}
