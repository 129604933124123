.search-form {
  display: flex;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  width: fit-content;
  height: 43px;
  max-width: 100vw;
  margin: 0 auto;
  border: 1px solid #555;

  .search-input {
    padding: 10px;
    border: none;
    height: 44px;
    &:focus {
      outline: none;
    }
  }

  .search-btn {
    display: inline-block;
    padding: 5px 10px;
    border: none;
    outline: none;
    background-color: #555;
    color: #fff;
  }
}
