.footer-nav-bar {
  border-top: 2px solid #487eb0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #192a56;
  color: #fff;
  z-index: 10;
  padding: 20px 0;

  h4 {
    margin-bottom: 20px;
  }
}

.footer-social-media-wrapper {
  margin: 0 auto;
  width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
}

.social-media-logo-wrapper {
  width: 50px;
  height: 50px;
}
.social-media-logo {
  width: 100%;
  height: 100%;
}

.github-logo {
  color: #fff;
  width: 80%;
  height: 80%;
  transform: translateY(13%);
}
