.map-container {
  width: 1000px;
  margin: 20px auto;
  height: 534px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  .map-canvas-container {
    position: relative;
    width: 100%;
    height: 500px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      height: 500px;
    }
  }

  .my-location-map-container {
    width: 100%;
    height: 500px;
    position: relative;
  }
}
