.bar-chart-container {
  width: 100%;
  max-width: 100vw;
  height: 600px;
  .x-axis-label {
    font-size: 16px;
    transform: rotate(90deg);
  }

  .y-axis-label-font-size {
    font-size: 16px;
  }

  .x-bar-label {
    font-size: 16px;
  }

  .y-bar-label {
    font-size: 16px;
  }

  .y-axis-label {
    font-size: 16px;
    transform: translate(-10px, -20px);
  }

  .x-axis-line {
    stroke: #ccc;
  }
}
