.filter-container {
  text-align: center;
  .filter-selection-container {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    width: fit-content;
    margin: 0 auto 20px;
    margin-block-start: 0;

    @media screen and (max-width: 620px) {
      border-radius: 0;
    }

    .filter {
      list-style: none;
      padding: 10px;
      background-color: #ea7773;
      transition: background-color 1s ease;
      cursor: pointer;
      display: flex;
      align-items: center;

      .filter-text {
        color: #fff;
      }
    }
  }
}
