.pricing-container {
  width: fit-content;
  overflow: hidden;
  margin: 0 auto 40px;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  .flex-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-gap: 20px;

    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #b3a394;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cdd7d6;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(31, 20, 70);
    }

    .pricing-item {
      padding: 20px 40px;
      height: calc(100% - 20px);
      box-shadow: 0 0 1px #555;
      line-height: 2;
      margin: 10px;
      transition: background-color 0.5s ease, color 0.5s ease;
      user-select: none;

      &:hover {
        background-color: #ffc1a6;
        color: #555;
      }
      @media screen and (max-width: 1200px) {
        margin: 10px 0px;
      }

      .service-list {
        li {
          margin: 10px 0;
        }
      }
    }
  }
}
