.landing-video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  .video-container {
    position: relative;
  }

  /* video styles */
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .header {
    text-align: center;
    position: absolute;
    width: fit-content;
    top: 50vh;
    right: 50vw;
    transform: translate(50%, -50%);
    h1 {
      color: #fff;
      margin-bottom: 20px;
    }

    button {
      border: none;
      background-color: #9370db;
      border-radius: 2px;
      padding: 10px 20px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}
