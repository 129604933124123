.success-message-container {
  position: fixed;
  top: 0;
  right: 50%;
  width: 300px;
  transform: translate(50%, 0);
  border-radius: 0 0 20px 20px;
  animation-name: successAddToCartAnimation;
  animation-duration: 0.3s;
  overflow: hidden;
  z-index: 10000;

  @keyframes successAddToCartAnimation {
    0% {
      width: 0;
      min-width: 0;
    }
    100% {
      width: 300px;
    }
  }

  .success-message {
    width: 300px;
    background-color: #009b48;
    color: #fff;
    text-align: center;
    padding: 10px 10px;
  }
}
