* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  overflow-x: hidden;
  color: #555;
}

.App {
  text-align: center;
}

p {
  line-height: 1.5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
