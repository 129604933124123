html {
  scroll-behavior: smooth;
}
.page-container {
  max-width: 100vw;
  overflow: hidden;
  min-height: 80vh;
  margin-top: 80px;

  @media screen and (max-width: 700px) {
    margin-top: 90px;
  }
}

.profile-container {
  padding: 40px 20px;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1000px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }

  .profile-picture-container {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;

    background-color: #eee;
    position: relative;
    .profile-picture-loading {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 850px) {
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
      }
    }
    .profile-picture {
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width: 850px) {
      width: calc(100vw - 40px);
      height: calc(100vw - 40px);
    }
  }

  .profile-description {
    line-height: 2;
    width: 750px;
    padding: 0 20px;
    @media screen and (max-width: 1000px) {
      width: calc(100vw - 250px - 80px);
    }
    @media screen and (max-width: 850px) {
      width: 100%;
      padding: 0;
    }
  }
}

.container-title {
  margin: 20px 0;

  font-size: 40px;
  text-align: center;
  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
}

.loading-page-container {
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-animation {
  animation: loading-page 1s infinite;
  animation-timing-function: ease;
}

@keyframes loading-page {
  50% {
    transform: rotate3d(1, 1, 1, 45deg);
  }
}

.unlocated-addresses-container {
  overflow-y: hidden;
  padding: 20px 10px;
  margin: 0 auto;
  width: calc(900px + 20px);

  @media screen and (max-width: 920px) {
    padding: 20px 0;
    width: fit-content;
  }

  .unlocated-address-table {
    width: 900px;
    margin-bottom: 20px;
    border-collapse: collapse;
    text-transform: capitalize;

    @media screen and (max-width: 900px) {
      width: 100vw;
    }
  }
}

.unlocated-address-sort-container {
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  width: 350px;
  align-items: center;
  @media screen and (max-width: 900px) {
    width: 100vw;
    padding: 0 10px;
    margin-bottom: 0;
  }
}

.check-forms-table-container {
  width: 100vw;
  overflow-y: scroll;
  margin-bottom: 20px;

  .check-forms-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: fit-content;
    table-layout: fixed;
    margin: 0 auto;

    .check-forms-table-row {
      td,
      th {
        border: 1px solid #bbb;
        padding: 5px;
      }

      &:nth-child(even) {
        background-color: #f2f2f2;
      }

      &:hover {
        background-color: #ddd;
      }

      th {
        background-color: #ff3b3b;
        color: #fff;
      }
    }
  }
}

.checklist-container {
  display: flex;
  padding: 20px;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}

.truck-image-container {
  margin: 0 auto 20px;
  display: grid;
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-rows: repeat(3, calc(55px));
}

.rec-truck-image-container {
  grid-template-columns: repeat(5, 80px);
  width: 400px;
  height: 225px;

  @media screen and (max-width: 440px) {
    width: calc(100vw - 40px);
    height: calc((100vw - 40px) * 225 / 400);
    grid-template-columns: repeat(5, calc((100vw - 40px) / 5));
  }
}
