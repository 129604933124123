.page-loading-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: #fff;
  overflow: hidden;

  .page-loading-content-container {
    height: 200px;
    margin: calc((100vh - 200px) / 2) auto;
    font-family: "Quicksand", sans-serif;
    text-shadow: 0px 0px 1px #eee;
  }
}
