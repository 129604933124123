.landing-background {
  height: calc(1200px * 0.5625);
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    width: 100vw;
    height: calc(100vw * 0.5625);
  }
  img {
    width: 100%;
    height: 100%;
  }
}
